import styled from 'styled-components';
import { Container as BadgeContainer } from '../../../molecules/Badge/Badge';
import { WaveWrapper } from '../../../molecules/Section/SectionBg/SectionBgHexes.styles';
import ListItemStyle from '../../../styles/Components/ListItems/ListContainerStyles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  --bg-color: ${props =>
    props.background === 'Charcoal' ? colors.gray[700] : '#21515c'};
  --wave-color: ${props =>
    props.background === 'Charcoal' ? '#484a5a' : '#1e4b56'};
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 32px;
  background-color: var(--bg-color);
  color: ${colors.base.white};
  border-radius: 12px;
  overflow: hidden;

  > * {
    position: relative;
  }

  ${WaveWrapper} {
    display: contents;

    svg {
      position: absolute;
      height: auto;
      fill: var(--wave-color);
      width: 110%;
      top: initial;
      bottom: -20%;
      right: -10%;

      ${atMinWidth.sm`
        width: 90%;
        bottom: -20%;
      `}

      ${atMinWidth.md`
        width: 80%;
        right: -10%;
      `}

      ${atMinWidth.lg`
        width: 110%;
        bottom: -25%;
        right: -15%;
      `}

      ${atMinWidth.xl`
        width: 120%;
        bottom: -45%;
        right: -15%;
      `}
    }
  }
`;

export const Heading = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  ${BadgeContainer} {
    margin-bottom: 4px;
  }
`;

export const CardTitle = styled.h2`
  ${font('display', 'md', '700')}
  font-size: 1.75rem;
  text-align: left;

  ${atMinWidth.sm`
    text-align: left;
  `}

  ${atMinWidth.lg`
    ${font('display', 'lg', '700')}
    text-align: left;
  `}
`;

export const Subhead = styled.div`
  width: 100%;
  ${font('text', 'sm', '600')}
  text-align: left;
  color: ${colors.base.white};

  ${atMinWidth.sm`
    ${font('text', 'md', '600')}
    width: 85%;
    text-align: left;
`}
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  ${font('display', 'sm', '700')}
  text-align: left;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  .pricing-btn {
    white-space: nowrap;

    ${atMinWidth.lg`
      ${font('display', 'lg', '700')}
    `}
  }
`;

export const Price = styled.span`
  ${font('display', 'lg', '700')}
`;

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 16px;
  text-align: left;

  ${ListItemStyle} {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    align-items: flex-start;
    justify-content: start;
    width: 100%;
    padding: 0;
    margin-top: 12px;

    p,
    h2,
    h3,
    h4 {
      ${font('text', 'xs', '600')}
      color: ${colors.base.white};
    }

    .list-item {
      display: flex;
      gap: 8px;
      margin: 0;
    }

    .list-icon {
      margin: 0;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        background: ${colors.base.white};
        border-radius: 50%;
      }

      img {
        position: relative;
        z-index: 1;
        display: block;
      }
    }

    .list-heading {
      margin: 0;
      white-space: nowrap;
      text-transform: none;
    }
  }
`;
