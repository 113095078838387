import styled, { css } from 'styled-components';
import { SectionStyle } from '../../../molecules/Section/Section.styles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { paddingY } from '../../../styles/atoms/spacing';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  max-width: 770px;
  border-radius: 0 0 10px 10px;
  ${paddingY(32)}
  ${shadow('none')};
  transition: max-width 200ms ease-in-out;
`;

export const ToggleSection = styled(SectionStyle)`
  @media (max-width: 990px) {
    width: 100% !important;
  }

  ${atMinWidth.lg`
    position: sticky;
    width: 100%;
    top: 75px;
    z-index: 100;

    ${props =>
      props.isStuck &&
      css`
        width: 100vw;
        background-color: transparent;

        ${Wrapper} {
          background-color: ${colors.base.white};
          ${shadow('md')}
        }

        &.sticky-block-menu ${Wrapper} {
          max-width: 1298px;
        }
      `}
  `}

  ${atMinWidth.xl`
    top: 100px;
  `}

  &.hidden ${Wrapper} {
    ${atMinWidth.lg`
      transform: translateY(calc(-100% - 10px));
    `}
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const RateHeading = styled.div`
  ${props =>
    props.active ? font('text', 'sm', '700') : font('text', 'sm', '400')}
  color: ${colors.gray[900]}
`;
