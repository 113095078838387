import styled, { css } from 'styled-components';
import { InnerWrapper } from '../../../molecules/Section/Section.styles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const customSectionStyle = css`
  position: static;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    transform: translateY(-100px);
    background-color: ${colors.base.heroSurface};
  }

  ${InnerWrapper} {
    ${atMinWidth.lg`
      max-width: 100%;
    `}

    ${atMinWidth.xl`
      max-width: 1218px;
    `}
  }
`;

export const customInnerStyle = css`
  && {
    padding-top: 16px;
    padding-bottom: 48px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  gap: 28px;

  .cta-container {
    display: flex;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 32px;
  width: 100%;

  ${atMinWidth.lg`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    margin-top: 24px;
  `}
`;

export const Callout = styled.div`
  width: 100%;
  padding: 28px;
  border-radius: 12px;
  border: 1px solid ${colors.primary[100]};
  background-color: ${colors.primary[50]};
  text-align: center;
  ${font('display', 'sm', '700')}

  > :last-child {
    margin-bottom: 0;
  }

  a {
    white-space: nowrap;
  }
`;

export const PricingAddOnsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;

  ${atMinWidth.lg`
    flex-direction: row;
  `}

  > * {
    flex: none;

    ${atMinWidth.lg`
      flex: 1 1 50%;
    `}
  }
`;
