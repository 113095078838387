import MuiSwitch from '@material-ui/core/Switch';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/atoms/colors';

export const Switch = styled(props => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))`
  && {
    width: 44px;
    height: 21px;
    padding: 0;

    & .MuiSwitch-input {
      position: absolute;
      opacity: 0;
      left: unset;
      width: 100%;

      &::before {
        content: '';
        position: relative;
        padding: 0 40px;
        left: -250%;
        top: -50%;
        height: 100%;
        width: 100%;
      }
    }

    & .MuiSwitch-switchBase {
      padding: 0;
      margin: 3px;
      color: ${colors.green[600]};
      transition-duration: 300ms;

      &.Mui-checked {
        transform: translateX(22px);

        & + .MuiSwitch-track {
          background-color: ${colors.gray[900]};
          opacity: 1;
          border: 0;
        }

        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }

      &.Mui-focusVisible .MuiSwitch-thumb {
        color: #33cf4d;
        border: 6px solid ${colors.base.white};
      }

      &.Mui-disabled .MuiSwitch-thumb {
        color: ${colors.gray[100]};
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.7;
      }
    }

    & .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 15px;
      height: 15px;
    }

    & .MuiSwitch-track {
      border-radius: 26px;
      background-color: ${colors.gray[900]};
      opacity: 1;
      transition: background-color 500ms ease-in-out;
    }
  }
`;
